import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "zimmervermietung-nancy-metz"
    }}><strong parentName="h1">{`Zimmervermietung Nancy Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Vermietung von Hochzeitsräumen Nancy Metz",
              "title": "Vermietung von Hochzeitsräumen Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Vermietung von Hochzeitsräumen Nancy Metz`}</strong></p>
    <h2 {...{
      "id": "ein-hochzeitsort-in-lothringen"
    }}>{`Ein `}<strong parentName="h2">{`Hochzeitsort`}</strong>{` in `}<strong parentName="h2">{`Lothringen`}</strong></h2>
    <h3 {...{
      "id": "organisieren-sie-ihre-veranstaltung-zwischen-nancy-und-metz"
    }}>{`Organisieren Sie Ihre Veranstaltung zwischen `}<strong parentName="h3">{`Nancy`}</strong>{` und `}<strong parentName="h3">{`Metz`}</strong></h3>
    <p>{`Das Château de Morey begrüßt Ihre Empfänge an seinen authentischen und warmen Orten. Machen Sie Ihre Veranstaltungen zu einzigartigen Momenten.`}</p>
    <p>{`3 Säle: 1 Saal für 60/65 Erwachsene - 1 Saal für 15/20 Kinder - 1 Tanzsaal Ein Park von mehr als einem Hektar, mit hundertjährigen Bäumen und singenden Vögeln wird Sie an einen anderen Ort versetzen.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Broschüre anfordern</ButtonInternal>
    <h2 {...{
      "id": "hochzeiten-empfänge-und-cocktails-in-der-nähe-von-nancy"
    }}><strong parentName="h2">{`Hochzeiten`}</strong>{`, `}<strong parentName="h2">{`Empfänge`}</strong>{` und Cocktails in der Nähe von `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "Firmenseminare Nancy und Metz",
              "title": "Firmenseminare Nancy und Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Firmenseminare Nancy und Metz`}</strong></p>
    <h3 {...{
      "id": "feiern-sie-ihre-vereinigung-feiern-sie-ihren-geburtstag-brunch-mit-ihrer-familie--in-der-nähe-von-metz-und-nancy"
    }}>{`Feiern Sie Ihre Vereinigung? Feiern Sie Ihren Geburtstag? Brunch mit Ihrer Familie ? in der Nähe von `}<strong parentName="h3">{`Metz`}</strong>{` und `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Staunen Sie über sich und Ihre Gäste, indem Sie einen Abend im Château de Morey verbringen. Die traditionellen Steinmauern unserer Zimmer werden Sie in eine schicke und raffinierte Atmosphäre eintauchen lassen. Zwischen Nancy und Metz kann Ihre Veranstaltung jeden Stil annehmen, mittelalterlich, raffiniert, modern, ländlich, böhmisch … Passen Sie unsere Räumlichkeiten nach Ihrem Geschmack an. Es ist jedoch immer schön, daran zu denken, dass ein charmanter Empfangsort weniger Dekorationskosten verursacht.`}</p>
    <p>{`Wir empfangen seit mehr als 20 Jahren Hochzeiten in Lothringen, deshalb begleiten wir Sie gerne bei der Organisation Ihrer Veranstaltung. Wir sind seit Jahren mit dieser Gegend vertraut, aber wir staunen immer wieder über die Hochzeiten, die wir dank Ihrer Authentizität empfangen.`}</p>
    <p>{`In Meurthe-et-Moselle ist es möglich, Ihre Veranstaltung in ein einzigartiges Souvenir zu verwandeln. Wir verfügen über einen bukolischen Park von mehr als einem Hektar, der bei Ihren Empfängen Platz bietet. Dort können Sie Ihre Kinder zwischen unseren libanesischen Zedern rennen sehen, Ihre Verwandten anstoßen und dabei die außergewöhnliche Aussicht auf das Tal genießen… Genießen Sie einen Familienmoment in einem großen und warmen Haus, das Ihnen das Gefühl gibt, Ihre Lieben zu Hause zu empfangen.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/mariage-clef-main-a-nancy/" mdxType="ButtonInternal">Hochzeits-Paket</ButtonInternal>
    <ButtonInternal to="/location-salles-nancy-metz/mariages/" mdxType="ButtonInternal">Hochzeiten</ButtonInternal>
    <h2 {...{
      "id": "ihre-fachveranstaltungen-nur-wenige-minuten-von-nancy-und-metz-entfernt"
    }}>{`Ihre Fachveranstaltungen nur wenige Minuten von `}<strong parentName="h2">{`Nancy`}</strong>{` und `}<strong parentName="h2">{`Metz`}</strong>{` entfernt`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Vermietung von Hochzeitsräumen Nancy Metz",
              "title": "Vermietung von Hochzeitsräumen Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Vermietung von Hochzeitsräumen Nancy Metz`}</strong></p>
    <h3 {...{
      "id": "teambildung--konferenz--betriebsfeier--seminar-zu-hause-"
    }}>{`Teambildung ? Konferenz ? Betriebsfeier ? Seminar zu Hause ?`}</h3>
    <p>{`Im Château de Morey werden spezielle Firmenpakete angeboten. Nutzen Sie diesen Ort in Lothringen, um Ihre Mitarbeiter, Ihre Partner zu informieren, ihnen zu danken und sie zu ermutigen…`}</p>
    <p>{`Genießen Sie einen professionellen Moment, in einem herzlichen Rahmen zwischen Nancy und Metz. Verbinden Sie das Nützliche mit dem Angenehmen, wir kümmern uns um die Organisation Ihrer Veranstaltungen:`}</p>
    <p>{`Menüs für jeden Geschmack und jedes Budget
An Ihre Sitzungen angepasste Set-ups
Eine Liste von Anbietern zur Unterhaltung Ihrer Teams
Vom abendlichen Cocktail bis hin zu Seminaren mit Übernachtung heißen wir Sie mit großer Freude willkommen. Unsere 5 Gästezimmer bieten Ihnen die Möglichkeit, bei Bedarf während Ihrer Veranstaltung von einer Unterkunft zu profitieren.`}</p>
    <p>{`Der Charme des Château verbindet auch Ruhe und Entspannung mit seinem über einen Hektar großen, bewaldeten Park. Ein komfortabler Raum, mitten in der Natur und doch in der Nähe von Nancy und Metz.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Erhalten Sie Ihr Angebot</ButtonInternal> 
    <h2 {...{
      "id": "unsere-veranstaltungen-in-der-nähe-von-nancy"
    }}>{`Unsere Veranstaltungen in der Nähe von `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <h3 {...{
      "id": "wir-feiern-auch-gern"
    }}>{`Wir feiern auch gern…`}</h3>
    <p>{`Es ist uns eine Freude, eine gute Zeit mit Ihnen zu teilen. Jedes Jahr organisieren wir etwa zehn Veranstaltungen. Verbringen Sie einen lebhaften Abend zwischen Nancy und Metz.`}</p>
    <p>{`Neujahr, Valentinstag, Brunch, Abendessen im Dunkeln, Dinner-Show… Genießen Sie in Lothringen einen festlichen Moment, in Begleitung Ihrer Familie, Freunde, Begleiter oder Ihrer Kinder. Unsere Veranstaltungen sprechen alle Altersgruppen an! Die großen Räumlichkeiten des Château de Morey ermöglichen es Ihnen, bei allen Gelegenheiten einen warmen und freundlichen Moment zu verbringen.`}</p>
    <p>{`Es ist ein Vergnügen, Sie unsere Partner bei diesen Veranstaltungen entdecken zu lassen, die leisen Stimmen unserer Lieblingssänger, die Gerichte unserer Lieblings-Caterer …`}</p>
    <p>{`Sie können unserer Gemeinschaft beitreten, um über jede unserer Veranstaltungen informiert zu werden.`}</p>
    <ButtonInternal to="/de/newsletter/" mdxType="ButtonInternal">Abonnieren Sie unseren Newsletter</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      